// export const REST_API_END_POINT = "http://localhost:4000/visa/";
// export const REST_API = "http://localhost:4000/";

export const MAP_API_KEY = 'AIzaSyD9vQeloU0By-tgfPWLErF8StGIPPamjt8';
// export const MAP_API_KEY = 'pk.eyJ1IjoidmlzYXNlYXJjaCIsImEiOiJjbHo2a3lzanoxaHp6MmpxMjNjcDQyNGs3In0.uRtxhuAaWdb259Tv44Wv5g'


// export const REST_API_END_POINT = "https://visa-api.skyniche.website/visa/";
// export const REST_API = "https://visa-api.skyniche.website/";



//port sharing
// export const REST_API_END_POINT = "http://192.168.1.19:4000/visa/";
// export const REST_API = "http://192.168.1.19:4000/";


// AWS server
export const REST_API_END_POINT = "https://visa-api.visasearch.info/visa/";
export const REST_API = "https://visa-api.visasearch.info/";



export const splitLocation = (location) => {
    if (!location) return {};
    const [address = '', city = '', district = '', state = '', country = ''] = location.split(',');
    return { address, city, district, state, country };
  };