// import { useState } from 'react';
// import PropTypes from 'prop-types';
// import { Icon } from '@iconify/react';
// import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
// import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// // material
// import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
// import { Box, List, ListItem, Collapse, ListItemText, ListItemIcon, ListSubheader } from '@material-ui/core';

// // ----------------------------------------------------------------------

// const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
//   ({ theme }) => ({
//     ...theme.typography.overline,
//     marginTop: theme.spacing(3),
//     marginBottom: theme.spacing(2),
//     paddingLeft: theme.spacing(5),
//     color: theme.palette.text.primary
//   })
// );

// const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(({ theme }) => ({
//   ...theme.typography.body2,
//   height: 48,
//   position: 'relative',
//   textTransform: 'capitalize',
//   paddingLeft: theme.spacing(5),
//   paddingRight: theme.spacing(2.5),
//   color: theme.palette.text.secondary,
//   '&:before': {
//     top: 0,
//     right: 0,
//     width: 3,
//     bottom: 0,
//     content: "''",
//     display: 'none',
//     position: 'absolute',
//     borderTopLeftRadius: 4,
//     borderBottomLeftRadius: 4,
//     backgroundColor: theme.palette.primary.main
//   }
// }));

// const ListItemIconStyle = styled(ListItemIcon)({
//   width: 22,
//   height: 22,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center'
// });

// // ----------------------------------------------------------------------

// NavItem.propTypes = {
//   item: PropTypes.object,
//   active: PropTypes.func
// };

// function NavItem({ item, active }) {
//   const theme = useTheme();
//   const isActiveRoot = active(item.path);
//   const { title, path, icon, info, children } = item;
//   const [open, setOpen] = useState(isActiveRoot);

//   const handleOpen = () => {
//     setOpen((prev) => !prev);
//   };

//   const activeRootStyle = {
//     color: 'primary.main',
//     fontWeight: 'fontWeightMedium',
//     bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
//     '&:before': { display: 'block' }
//   };

//   const activeSubStyle = {
//     color: 'text.primary',
//     fontWeight: 'fontWeightMedium'
//   };

//   if (children) {
//     return (
//       <>
//         <ListItemStyle
//           onClick={handleOpen}
//           sx={{
//             ...(isActiveRoot && activeRootStyle)
//           }}
//         >
//           <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
//           <ListItemText disableTypography primary={title} />
//           {info && info}
//           <Box
//             component={Icon}
//             icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
//             sx={{ width: 16, height: 16, ml: 1 }}
//           />
//         </ListItemStyle>

//         <Collapse in={open} timeout="auto" unmountOnExit>
//           <List component="div" disablePadding>
//             {children.map((item) => {
//               const { title, path } = item;
//               const isActiveSub = active(path);

//               return (
//                 <ListItemStyle
//                   key={title}
//                   component={RouterLink}
//                   to={path}
//                   sx={{
//                     ...(isActiveSub && activeSubStyle)
//                   }}
//                 >
//                   <ListItemIconStyle>
//                     <Box
//                       component="span"
//                       sx={{
//                         width: 4,
//                         height: 4,
//                         display: 'flex',
//                         borderRadius: '50%',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         bgcolor: 'text.disabled',
//                         transition: (theme) => theme.transitions.create('transform'),
//                         ...(isActiveSub && {
//                           transform: 'scale(2)',
//                           bgcolor: 'primary.main'
//                         })
//                       }}
//                     />
//                   </ListItemIconStyle>
//                   <ListItemText disableTypography primary={title} />
//                 </ListItemStyle>
//               );
//             })}
//           </List>
//         </Collapse>
//       </>
//     );
//   }

//   return (
//     <ListItemStyle
//       component={RouterLink}
//       to={path}
//       sx={{
//         ...(isActiveRoot && activeRootStyle)
//       }}
//     >
//       <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
//       <ListItemText disableTypography primary={title} />
//       {info && info}
//     </ListItemStyle>
//   );
// }

// NavSection.propTypes = {
//   navConfig: PropTypes.array
// };

// export default function NavSection({ navConfig, ...other }) {
//   const { pathname } = useLocation();
//   const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

//   return (
//     <Box {...other}>
//       {navConfig.map((list) => {
//         const { subheader, items } = list;
//         return (
//           <List key={subheader} disablePadding>
//             <ListSubheaderStyle>{subheader}</ListSubheaderStyle>
//             {items.map((item) => (
//               <NavItem key={item.title} item={item} active={match} />
//             ))}
//           </List>
//         );
//       })}
//     </Box>
//   );
// }



import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, List, ListItem, Collapse, ListItemText, ListItemIcon, ListSubheader } from '@material-ui/core';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary
  })
);

const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

 function NavItem({ item, active,open,onToggle}) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  // const [open, setOpen] = useState(isActiveRoot);

  // const handleOpen = () => {
  //   setOpen((prev) => !prev);
  // };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
            // onClick={handleOpen}
            onClick={onToggle}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          { <ListItemIconStyle>{icon && icon}</ListItemIconStyle> }
          {/* <ListItemIcon>{icon}</ListItemIcon> */}
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* {children.map((item) => { */}
            {children.map((child) => {
              // const { title, path } = item;
              const isActiveSub = active(child.path);

              return (
                <ListItemStyle
                  //  key={title}
                   key={child.title}
                  component={RouterLink}
                  //  to={path}
                   to={child.path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                          // bgcolor: isActiveSub ? 'primary.main' : 'text.disabled'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  {/* <ListItemText disableTypography primary={title} /> */}
                  <ListItemText disableTypography primary={child.title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {/* <ListItemIconStyle>{icon}</ListItemIconStyle> */}
      
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

// export default function NavSection({ navConfig, ...other }) {
//   const { pathname } = useLocation();
//   const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

//   return (
//     <Box {...other}>
//       {navConfig.map((list) => {
//         const { subheader, items } = list;
//         return (
//           <List key={subheader} disablePadding>
//             <ListSubheaderStyle>{subheader}</ListSubheaderStyle>
//             {items.map((item) => (
//               <NavItem key={item.title} item={item} active={match} />
//             ))}
//           </List>
//         );
//       })}
//     </Box>
//   );
// }



export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  
  const [openMenu, setOpenMenu] = useState(null); // Track which menu is open

  const handleToggle = (title) => {
    setOpenMenu((prev) => (prev === title ? null : title)); // Collapse previous if new one is opened
  };

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            <ListSubheader>{subheader}</ListSubheader>
            {items.map((item) => (
              <NavItem
                key={item.title}
                item={item}
                active={match}
                open={openMenu === item.title} // Pass open state
                onToggle={() => handleToggle(item.title)} // Pass toggle function
              />
            ))}
          </List>
        );
      })}
    </Box>
  );
}

