import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from 'axios';
import { isValidToken, setSession } from '../utils/jwt';
import { REST_API } from 'src/constant/defaultValue';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { REST_API_END_POINT } from 'src/constant/defaultValue';
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'Visa Search Admin',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

let visaUserType = null;
let EmailOTP=null

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate=useNavigate()
  

  
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          // const response = await axios.get('/api/account/my-account');
          const user = JSON.parse(localStorage.getItem('userData'))
          // const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(`${REST_API}login`, {
     username: email,
      password
    });
    const { accessToken, user ,userType,companyId} = response.data;  

    localStorage.setItem('mainId', companyId);


    if(userType===1){
      visaUserType = 1
      localStorage.setItem('user_type', 1);
      if(accessToken){
        localStorage.setItem('user_id', user.user_id);
        localStorage.setItem('user_type', userType);
        localStorage.setItem('userData',JSON.stringify(user))
      }
      setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          visaUserType:1
        }

      });
      navigate(PATH_DASHBOARD.visa.root)
      window.location.reload()
    }else if(userType==4){
      visaUserType = 4
      localStorage.setItem('user_type', 4);
           
      if(accessToken){
        localStorage.setItem('user_id', user.user_id);
        localStorage.setItem('user_type', userType);
        localStorage.setItem('userData',JSON.stringify(user))
      }
      setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          visaUserType : 4
        }
      });
      navigate(PATH_DASHBOARD.insurance.insuranceDetails,{state:{c_id:companyId}})
      window.location.reload()

    }else if(userType==5){
      visaUserType = 5
      localStorage.setItem('user_type', 5);
           
      if(accessToken){
        localStorage.setItem('user_id', user.user_id);
        localStorage.setItem('user_type', userType);
        localStorage.setItem('userData',JSON.stringify(user))
      }
      setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          visaUserType : 5
        }
      });
      navigate(PATH_DASHBOARD.universities.universityDetails,{state:{u_id:companyId}})
      window.location.reload()
    }
    else if(userType==8){
      visaUserType = 8
      localStorage.setItem('user_type', 8);
           
      if(accessToken){
        localStorage.setItem('user_id', user.user_id);
        localStorage.setItem('user_type', userType);
        localStorage.setItem('userData',JSON.stringify(user))
      }
      setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          visaUserType : 8
        }
      });
      navigate(PATH_DASHBOARD.credits.creditsDetails,{state:{bankId:companyId}})
      window.location.reload()
    }
    
    else if(userType==6){
      visaUserType = 6
      localStorage.setItem('user_type', 6);
      if(accessToken){
        localStorage.setItem('user_id', user.user_id);
        localStorage.setItem('user_type', userType);
        localStorage.setItem('userData',JSON.stringify(user))
      }
      setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          visaUserType : 6
        }
      });
      navigate(PATH_DASHBOARD.consultants.consultantsDetails,{state:{consultId:companyId}})
      window.location.reload()
    }
    else if(userType==7){
      visaUserType = 7
      localStorage.setItem('user_type', 7);
      if(accessToken){
        localStorage.setItem('user_id', user.user_id);
        localStorage.setItem('user_type', userType);
        localStorage.setItem('userData',JSON.stringify(user))
      }
      setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          visaUserType : 7
        }
      });
      navigate(PATH_DASHBOARD.languages.languageDetails,{state:{l_id:companyId}})
      window.location.reload()
    }
    else{
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    setSession(null);
    let userData=JSON.parse(window.localStorage.getItem('userData'))
    const response = await axios.post(REST_API+'logout', {
     userId:userData.user_id
    });
    if(response.data.status==1){
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_type')
      localStorage.removeItem('userData')
      dispatch({ type: 'LOGOUT',payload: {
        isAuthenticated: false,
        user: null
      } })
    }
  };

  const resetPassword = (email) => {
    // console.log("email",email);
    axios.post(`${REST_API}resetPassword-sent-otp`,{email})
    .then(res =>{
     if(res.data.status===1) {
      //  console.log("response",res.data)
     EmailOTP = res.data.otp
    }else {
     console.log("not getting otp");
    }
   })
    .catch(err =>console.log(err))
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Visa Search Admin',
        login,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider ,visaUserType,EmailOTP};
