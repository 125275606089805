import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DocsLayout from '../layouts/docs';
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        {
          path: 'analytics',
          element: <GeneralAnalytics />
        },
        {
          path: 'e-commerce',
          children: [
            { path: '/', element: <Navigate to="/dashboard/e-commerce/shop" replace /> },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> }
          ]
        },
        {
          path: 'visa',
          children: [
            { path: '/', element: <Navigate to="/dashboard/visa/country" replace /> },
            { path: 'country', element: <Countrylisting /> }, 
            { path: 'type-of-visa-tabs', element: <TypeOfVisaTabs /> }, 
            { path: 'country/add-new-country', element: <AddNewCountry /> }, 
            { path: 'country/add-country-visa', element: <AddCountryVisa /> }, 
            { path: 'data-variables', element: <DataVariables /> }, 
            { path: 'documents-required', element: <DocumentsRequired /> }, 
            { path: 'faq', element: <VisaFAQ /> }, 
            { path: 'travel-advice', element: <TravelAdvice /> }, 
            { path: 'customs-info', element: <CustomsInfo /> }, 
            { path: 'data-variables/add-data-variables', element: <AddDataVariables /> }, 
            { path: 'form/list', element: <VisaFormListingTable /> },
            { path: 'country/add-new-visa', element: <AddNewVisa /> }, 
          ]
        },
        {
          path: 'check-visa',
          children: [
            { path: '/', element: <Navigate to="/dashboard/check-visa/check-my-visa" replace /> },
            { path: 'check-my-visa', element: <CheckMyVisa /> }, 
            { path: 'documents-required', element: <CheckMyVisaDocumentsRequired /> }, 
            { path: 'add-check-my-visa-data', element: <CheckVisaDataForm /> }, 
            { path: 'faq', element: <CheckMyVisaFAQ /> }, 
            // { path: 'country/add-new-country', element: <AddNewCountry /> }, 
          ]
        },
        {
          path: 'pr-pathway',
          children: [
            { path: '/', element: <Navigate to="/dashboard/pr-pathway/pr-pathway-listing" replace /> },
            { path: 'pr-pathway-listing', element: <PRPathwayCountry /> },
            { path: 'pr-pathway-document-points', element: <PR /> },
            { path: 'pr-pathway-listing/pr-pathway-details', element: <PRPathwayCountryDetails /> },
            {path:'occupation-list',element:<PrOccupationDetails />},
            { path: 'pr-pathway-data-variables', element: <PRDataVariables /> },
            { path: 'faq', element: <PRFaq /> },
            { path: 'pr-pathway-documents-required', element: <PRPathwayDocumentsRequired /> },
            { path: 'pr-pathway-listing/pr-details-form', element: <PRInnerPageDetailsForm /> },
            { path: 'pr-pathway-listing/add-new-pr-pathway', element: <AddNewPRPathwayCountry /> },
            { path: 'pr-pathways/bundle_listing', element: <PRBundleListing /> },
          ]
        },
        {
          path: 'insurance',
          children: [
            { path: '/', element: <Navigate to="/dashboard/insurance/insurance-listing" replace /> },
            { path: 'insurance-listing', element: <Insurance /> }, 
            { path: 'insurance-listing/insurance-details', element: <InsuranceDetails /> }, 
            { path: 'why-you-choose', element: <WhyYouChoose /> }, 
            { path: 'choose-right-cover', element: <ChooseRightCover /> }, 
            { path: 'faq', element: <FAQ /> }, 
            { path: 'add-benefits', element: <Benefits /> }, 
            { path: 'insurance-listing/add-new-insurance', element: <AddNewInsurance /> }, 
            { path: 'enquiries', element: <InsuranceEnquiryList /> }, 
          ]
        },
        {
          path: 'universities',
          children: [
            { path: '/', element: <Navigate to="/dashboard/universities/university-listing" replace /> },
            { path: 'university-listing', element: <University /> }, 
            { path: 'enquiry-listing', element: <UniversityEnquiryList /> }, 
            { path: 'documents-required', element: <UniversityDocumentsRequired /> }, 
            { path: 'all-about-course', element: <AllAboutTheCourse /> }, 
            { path: 'faq', element: <UniversityFAQ/> }, 
            { path: 'university-listing/add-new-university', element: <AddUniversity /> }, 
            { path: 'university-listing/university-details', element: <UniveristyDetails /> }, 
            { path: 'university-listing/university-details/course-details', element: <CourseDetails /> }, 
          ]
        },
        {
          path: 'consultants',
          children: [
            { path: '/', element: <Navigate to="/dashboard/consultants/consultants-listing" replace /> },
            { path: 'consultants-listing', element: <Consultants /> }, 
            { path: 'consultants-staffs', element: <ConsultantsStaffLisiting /> }, 
            { path: 'service-locations', element: <ServiceLocationListing /> }, 
            { path: 'service-locations/add-service-location', element: <AddServiceLocationForm /> }, 
            { path: 'offers', element: <OffersListing /> }, 
            { path: 'faq', element: <ConsultantFAQ/> }, 
            { path: 'consultants-listing/add-new-consultants', element: <AddNewConsultants /> }, 
            { path: 'consultants-listing/consultants-details', element: <ConsultantsDetails /> }, 
          ]
        },
        {
          path: 'languages',
          children: [
            { path: '/', element: <Navigate to="/dashboard/languages/languages-listing" replace /> },
            { path: 'languages-listing', element: <Languages /> }, 
            { path: 'languages-staffs', element: <LanguageStaffLisiting /> }, 
            { path: 'service-locations', element: <LanguageLocationListing /> }, 
            { path: 'service-locations/add-service-location', element: <LanguageAddServiceLocationForm /> }, 
            { path: 'offers', element: <LangaugeOffersListing /> }, 
            { path: 'faq', element: <LanguageFAQ /> }, 
            { path: 'languages-listing/add-new-languages', element: <AddNewLanguages /> }, 
            { path: 'languages-listing/languages-details', element: <LanguageDetails /> }, 
            { path: 'enquiry', element: <LanguageEnquiryList /> }, 
          ]
        },
        {
          path: 'credits',
          children: [
            { path: '/', element: <Navigate to="/dashboard/credits/credits-listing" replace /> },
            { path: 'credits-listing', element: <Credits /> },
            { path: 'enquiry-listing', element: <CreditEnquiryList /> },
            { path: 'documents-required', element: <CreditsDocumentsRequired /> },
            { path: 'data-variables', element: <CreditsDataVariables /> },
            { path: 'faq', element: <CreditFAQ /> },
            { path: 'consultant-near-locations', element: <ConsultantNearTable /> },
            { path: 'add-consultant-near-locations', element: <AddConsultantLocation /> },
            { path: 'credits-listing/add-new-bank', element: <AddNewCredits /> },
            { path: 'credits-listing/credits-details', element: <CreditsDetailsForm /> },
            { path: 'credits-listing/credits-details/loan-details', element: <LoanDetails /> }, 
          ]
        },
        {
          path:'enquiries',
          children:[
            { path: '/', element: <Navigate to="/dashboard/enquiries/website-enquiry" replace /> },
            { path: 'website-enquiry', element: <WebsiteEnquiryList/> },
            { path: 'partner-enquiry', element: <PartnerEnquiry/> },
            { path: 'service-enquiry', element: <ServiceEnquiryList /> },
          ]
        },
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: '/:name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'blog',
          children: [
            { path: '/', element: <Navigate to="/dashboard/blog/posts" replace /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { path: '/', element: <Navigate to="/dashboard/mail/all" replace /> },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },
        {
          path: 'chat',
          children: [
            { path: '/', element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> }
          ]
        },
        { path: 'calendar', element: <Calendar /> }
        // { path: 'kanban', element: <Kanban /> }
      ]
    },

    // Docs Routes
    {
      path: 'docs',
      element: <DocsLayout />,
      children: [
        { path: '/', element: <Navigate to="/docs/introduction" replace /> },
        { path: '*', element: <Docs /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <Login />,
      // element: <MainLayout />,
      children: [
        // Update the default route to render the Visa Country page
        // { path: '/', element: <Navigate to="/dashboard/visa/country" replace /> },  // <LandingPage />
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        {
          path: 'components',
          children: [
            { path: '/', element: <ComponentsOverview /> },
            // FOUNDATIONS
            { path: 'color', element: <Color /> },
            { path: 'typography', element: <Typography /> },
            { path: 'shadows', element: <Shadows /> },
            { path: 'grid', element: <Grid /> },
            { path: 'icons', element: <Icons /> },
            // MATERIAL UI
            { path: 'accordion', element: <Accordion /> },
            { path: 'alert', element: <Alert /> },
            { path: 'autocomplete', element: <Autocomplete /> },
            { path: 'avatar', element: <Avatar /> },
            { path: 'badge', element: <Badge /> },
            { path: 'breadcrumbs', element: <Breadcrumb /> },
            { path: 'buttons', element: <Buttons /> },
            { path: 'checkbox', element: <Checkbox /> },
            { path: 'chip', element: <Chip /> },
            { path: 'dialog', element: <Dialog /> },
            { path: 'label', element: <Label /> },
            { path: 'list', element: <List /> },
            { path: 'menu', element: <Menu /> },
            { path: 'pagination', element: <Pagination /> },
            { path: 'pickers', element: <Pickers /> },
            { path: 'popover', element: <Popover /> },
            { path: 'progress', element: <Progress /> },
            { path: 'radio-button', element: <RadioButtons /> },
            { path: 'rating', element: <Rating /> },
            { path: 'slider', element: <Slider /> },
            { path: 'snackbar', element: <Snackbar /> },
            { path: 'stepper', element: <Stepper /> },
            { path: 'switch', element: <Switches /> },
            { path: 'table', element: <Table /> },
            { path: 'tabs', element: <Tabs /> },
            { path: 'textfield', element: <Textfield /> },
            { path: 'timeline', element: <Timeline /> },
            { path: 'tooltip', element: <Tooltip /> },
            { path: 'transfer-list', element: <TransferList /> },
            { path: 'tree-view', element: <TreeView /> },
            // EXTRA COMPONENTS
            { path: 'chart', element: <Charts /> },
            { path: 'map', element: <Map /> },
            { path: 'editor', element: <Editor /> },
            { path: 'copy-to-clipboard', element: <CopyToClipboard /> },
            { path: 'upload', element: <Upload /> },
            { path: 'carousel', element: <Carousel /> },
            { path: 'multi-language', element: <MultiLanguage /> },
            { path: 'animate', element: <Animate /> },
            { path: 'mega-menu', element: <MegaMenu /> }
          ]
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const Countrylisting = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/country/country-listing')));
const CheckMyVisa = Loadable(lazy(() => import('../pages/dashboard/dashboard-check-my-visa/check-my-visa/check-my-visa')));
const CheckMyVisaDocumentsRequired = Loadable(lazy(() => import('../pages/dashboard/dashboard-check-my-visa/documents-required/check-my-visa-documents-required')));
const CheckVisaDataForm = Loadable(lazy(() => import('../pages/dashboard/dashboard-check-my-visa/check-my-visa/check-visa-data-form')));
const CheckMyVisaFAQ = Loadable(lazy(() => import('../pages/dashboard/dashboard-check-my-visa/faq/check-my-visaFAQ')));
const University = Loadable(lazy(() => import('../pages/dashboard/dashboard-universities/university/university')));
const UniversityEnquiryList = Loadable(lazy(() => import('../pages/dashboard/dashboard-universities/university-enquiry/UniversityEnquiryList')));
const PRPathwayCountry = Loadable(lazy(() => import('../pages/dashboard/dashboard-pr-pathway/pr-pathway-country/pr-pathway-country')));
const PR = Loadable(lazy(() => import('../pages/dashboard/dashboard-pr-pathway/pr/pr')));
const PRPathwayCountryDetails = Loadable(lazy(() => import('../pages/dashboard/dashboard-pr-pathway/pr-pathway-country/pr-pathway-country-details')));
const PrOccupationDetails = Loadable(lazy(() => import('../pages/dashboard/dashboard-pr-pathway/pr-pathway-country/pr-pathways/prOccupationDetail')));
const PRDataVariables = Loadable(lazy(() => import('../pages/dashboard/dashboard-pr-pathway/data-variables/pr-data-variables')));
const PRFaq = Loadable(lazy(() => import('../pages/dashboard/dashboard-pr-pathway/faq/pr-faq')));
const PRPathwayDocumentsRequired = Loadable(lazy(() => import('../pages/dashboard/dashboard-pr-pathway/documents-required/pr-pathway-documents-required')));
const PRInnerPageDetailsForm = Loadable(lazy(() => import('../pages/dashboard/dashboard-pr-pathway/pr-pathway-country/pr-pathways/pr-inner-page-details-form')));
const AddNewPRPathwayCountry = Loadable(lazy(() => import('../pages/dashboard/dashboard-pr-pathway/pr-pathway-country/add-pr-pathway-country')));
const PRBundleListing = Loadable(lazy(() => import('../pages/dashboard/dashboard-pr-pathway/pr-pathway-country/bundle-listing')));
const Consultants = Loadable(lazy(() => import('../pages/dashboard/dashboard-consultants/consultants/consultants')));
const ConsultantsStaffLisiting = Loadable(lazy(() => import('../pages/dashboard/dashboard-consultants/consultants-staffs/consultants-staff-listing')));
const ServiceLocationListing = Loadable(lazy(() => import('../pages/dashboard/dashboard-consultants/service-locations/service-location-listing')));
const AddServiceLocationForm = Loadable(lazy(() => import('../pages/dashboard/dashboard-consultants/service-locations/service-location-form')));
const LanguageLocationListing = Loadable(lazy(() => import('../pages/dashboard/dashboard-languages/service-locations/language-service-location-listing')));
const LanguageAddServiceLocationForm = Loadable(lazy(() => import('../pages/dashboard/dashboard-languages/service-locations/language-service-location-form')));
const OffersListing = Loadable(lazy(() => import('../pages/dashboard/dashboard-consultants/offers/offers-listing')));
const ConsultantFAQ = Loadable(lazy(() => import('../pages/dashboard/dashboard-consultants/faq/consultantFaq')));
const AddNewConsultants = Loadable(lazy(() => import('../pages/dashboard/dashboard-consultants/consultants/add-consultants')));
const ConsultantsDetails = Loadable(lazy(() => import('../pages/dashboard/dashboard-consultants/consultants/consultants-details')));
const Languages = Loadable(lazy(() => import('../pages/dashboard/dashboard-languages/languages/languages')));
const TypeOfVisaTabs = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/type-of-visa-tabs/type-of-visa-tabs')));
const Credits = Loadable(lazy(() => import('../pages/dashboard/dashboard-credits/credits/credits')));
const CreditEnquiryList = Loadable(lazy(() => import('../pages/dashboard/dashboard-credits/credit-enquiry/CreditEnquiryList')));
const CreditsDocumentsRequired = Loadable(lazy(() => import('../pages/dashboard/dashboard-credits/document-required/credits-documents-required')));
const CreditFAQ = Loadable(lazy(() => import('../pages/dashboard/dashboard-credits/faq/creditFAQ')));
const CreditsDataVariables = Loadable(lazy(() => import('../pages/dashboard/dashboard-credits/data-variables/credits-data-variables')));
const ConsultantNearTable = Loadable(lazy(() => import('../pages/dashboard/dashboard-credits/consultant-near-you/consultant-near-table')));
const AddConsultantLocation = Loadable(lazy(() => import('../pages/dashboard/dashboard-credits/consultant-near-you/add-consultant-near-form')));
const AddNewCredits = Loadable(lazy(() => import('../pages/dashboard/dashboard-credits/credits/add-new-credits-bank')));
const CreditsDetailsForm = Loadable(lazy(() => import('../pages/dashboard/dashboard-credits/credits/credits-details-form')));
const LoanDetails = Loadable(lazy(() => import('../pages/dashboard/dashboard-credits/credits/credits-loan-listing/credit-loan-page-details')));
const LanguageStaffLisiting = Loadable(lazy(() => import('../pages/dashboard/dashboard-languages/language-staffs/language-staff-listing')));
const LangaugeOffersListing = Loadable(lazy(() => import('../pages/dashboard/dashboard-languages/offers/language-offers-listing')));
const LanguageFAQ = Loadable(lazy(() => import('../pages/dashboard/dashboard-languages/faq/languageFAQ')));
const AddNewLanguages = Loadable(lazy(() => import('../pages/dashboard/dashboard-languages/languages/add-new-language')));
const LanguageDetails = Loadable(lazy(() => import('../pages/dashboard/dashboard-languages/languages/language-details')));
const LanguageEnquiryList = Loadable(lazy(() => import('../pages/dashboard/dashboard-languages/enquiry/language-enquiries')));
const Insurance = Loadable(lazy(() => import('../pages/dashboard/dashboard-insurance/insurance/insurance')));
const InsuranceDetails = Loadable(lazy(() => import('../pages/dashboard/dashboard-insurance/insurance/insurance-plan/insurance-details')));
const WhyYouChoose = Loadable(lazy(() => import('../pages/dashboard/dashboard-insurance/why-you-choose/why-you-choose')));
const ChooseRightCover = Loadable(lazy(() => import('../pages/dashboard/dashboard-insurance/choose-right-cover/choose-right-cover')));
const FAQ = Loadable(lazy(() => import('../pages/dashboard/dashboard-insurance/faq/faq')));
const Benefits = Loadable(lazy(() => import('../pages/dashboard/dashboard-insurance/benefits/benefits')));
const AddNewInsurance = Loadable(lazy(() => import('../pages/dashboard/dashboard-insurance/insurance/add-new-insurance')));
const InsuranceEnquiryList = Loadable(lazy(() => import('../pages/dashboard/dashboard-insurance/insurance/enquiries/insurance-enquiries')));
const UniversityDocumentsRequired = Loadable(lazy(() => import('../pages/dashboard/dashboard-universities/university-documents/university-documents-required')));
const AllAboutTheCourse = Loadable(lazy(() => import('../pages/dashboard/dashboard-universities/all-about-course/all-about-the-course')));
const UniversityFAQ = Loadable(lazy(() => import('../pages/dashboard/dashboard-universities/faq/universityFAQ')));
const AddUniversity = Loadable(lazy(() => import('../pages/dashboard/dashboard-universities/university/add-univerisity')));
const UniveristyDetails = Loadable(lazy(() => import('../pages/dashboard/dashboard-universities/university/university-details')));
const CourseDetails = Loadable(lazy(() => import('../pages/dashboard/dashboard-universities/university/course/course-details-inner-page-details')));
const AddNewCountry = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/country/add-new-country')));
const AddNewVisa = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/country/add-new-visa')));
const AddCountryVisa = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/country/visa/add-country-visa')));
const DataVariables = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/data-variables/visa-type')));
const WebsiteEnquiryList = Loadable(lazy(() => import('../pages/dashboard/dashboard-enquires/WebsiteEnquiry')));
const PartnerEnquiry = Loadable(lazy(() => import('../pages/dashboard/dashboard-enquires/PartnerEnquiry')));
const ServiceEnquiryList = Loadable(lazy(() => import('../pages/dashboard/dashboard-enquires/ServiceEnquiry')));
const DocumentsRequired = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/documents-required/documents-required')));
const VisaFAQ = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/faq/visaFAQ')));
const AddDataVariables = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/data-variables/add-visa-type-listng')));
const TravelAdvice = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/travel-advice/travel-advice')));
const CustomsInfo = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/customs-info/customs-info')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const VisaFormListingTable = Loadable(lazy(() => import('../pages/dashboard/dashboard-visa/country/visa-form-listing')));

// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Docs
const Docs = Loadable(lazy(() => import('../pages/Docs')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Components
const ComponentsOverview = Loadable(lazy(() => import('../pages/ComponentsOverview')));
const Color = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationColor')));
const Typography = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationTypography')));
const Shadows = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationShadows')));
const Grid = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationGrid')));
const Icons = Loadable(lazy(() => import('../pages/components-overview/foundations/FoundationIcons')));
const Accordion = Loadable(lazy(() => import('../pages/components-overview/material-ui/Accordion')));
const Alert = Loadable(lazy(() => import('../pages/components-overview/material-ui/Alert')));
const Autocomplete = Loadable(lazy(() => import('../pages/components-overview/material-ui/Autocomplete')));
const Avatar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Avatar')));
const Badge = Loadable(lazy(() => import('../pages/components-overview/material-ui/Badge')));
const Breadcrumb = Loadable(lazy(() => import('../pages/components-overview/material-ui/Breadcrumb')));
const Buttons = Loadable(lazy(() => import('../pages/components-overview/material-ui/buttons')));
const Checkbox = Loadable(lazy(() => import('../pages/components-overview/material-ui/Checkboxes')));
const Chip = Loadable(lazy(() => import('../pages/components-overview/material-ui/chips')));
const Dialog = Loadable(lazy(() => import('../pages/components-overview/material-ui/dialog')));
const Label = Loadable(lazy(() => import('../pages/components-overview/material-ui/Label')));
const List = Loadable(lazy(() => import('../pages/components-overview/material-ui/Lists')));
const Menu = Loadable(lazy(() => import('../pages/components-overview/material-ui/Menus')));
const Pagination = Loadable(lazy(() => import('../pages/components-overview/material-ui/Pagination')));
const Pickers = Loadable(lazy(() => import('../pages/components-overview/material-ui/pickers')));
const Popover = Loadable(lazy(() => import('../pages/components-overview/material-ui/Popover')));
const Progress = Loadable(lazy(() => import('../pages/components-overview/material-ui/progress')));
const RadioButtons = Loadable(lazy(() => import('../pages/components-overview/material-ui/RadioButtons')));
const Rating = Loadable(lazy(() => import('../pages/components-overview/material-ui/Rating')));
const Slider = Loadable(lazy(() => import('../pages/components-overview/material-ui/Slider')));
const Snackbar = Loadable(lazy(() => import('../pages/components-overview/material-ui/Snackbar')));
const Stepper = Loadable(lazy(() => import('../pages/components-overview/material-ui/stepper')));
const Switches = Loadable(lazy(() => import('../pages/components-overview/material-ui/Switches')));
const Table = Loadable(lazy(() => import('../pages/components-overview/material-ui/table')));
const Tabs = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tabs')));
const Textfield = Loadable(lazy(() => import('../pages/components-overview/material-ui/textfield')));
const Timeline = Loadable(lazy(() => import('../pages/components-overview/material-ui/Timeline')));
const Tooltip = Loadable(lazy(() => import('../pages/components-overview/material-ui/Tooltip')));
const TransferList = Loadable(lazy(() => import('../pages/components-overview/material-ui/transfer-list')));
const TreeView = Loadable(lazy(() => import('../pages/components-overview/material-ui/TreeView')));
const Charts = Loadable(lazy(() => import('../pages/components-overview/extra/Charts')));
const Map = Loadable(lazy(() => import('../pages/components-overview/extra/Map')));
const Editor = Loadable(lazy(() => import('../pages/components-overview/extra/Editor')));
const CopyToClipboard = Loadable(lazy(() => import('../pages/components-overview/extra/CopyToClipboard')));
const Upload = Loadable(lazy(() => import('../pages/components-overview/extra/Upload')));
const Carousel = Loadable(lazy(() => import('../pages/components-overview/extra/Carousel')));
const MultiLanguage = Loadable(lazy(() => import('../pages/components-overview/extra/MultiLanguage')));
const Animate = Loadable(lazy(() => import('../pages/components-overview/extra/animate')));
const MegaMenu = Loadable(lazy(() => import('../pages/components-overview/extra/MegaMenu')));
